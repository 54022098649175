import { lazy } from 'react'
import nav from './pages/nav'

export interface IRoute {
  title: string
  path: string
  element: any
  children?: IRoute[]
}

const users = lazy(() => import('./pages/users/list'))
const meituan = lazy(() => import('./pages/meituan'))
const shopRecharge = lazy(() => import('./pages/shop-recharge'))
const shopPackage = lazy(() => import('./pages/shop-package'))
const shopList = lazy(() => import('./pages/shop-list'))
const orders = lazy(() => import('./pages/orders'))
const recharge = lazy(() => import('./pages/recharge'))
const dataList = lazy(() => import('./pages/data-list'))
const refund = lazy(() => import('./pages/refund'))
const freePark = lazy(() => import('./pages/free-park'))
const transaction = lazy(() => import('./pages/transaction'))
const groupMapping = lazy(() => import('./pages/group-mapping'))
const blacklist = lazy(() => import('./pages/blacklist'))

// 要注册的路由
export const routes: IRoute[] = [
  {
    title: '首页',
    path: '/nav',
    element: nav
  },
  {
    title: '用户管理',
    path: '/users',
    element: users
  },
  {
    title: '交易数据',
    path: '/transaction',
    element: transaction
  },
  {
    title: '美团授权',
    path: '/meituan',
    element: meituan
  },
  {
    title: '充值套餐管理',
    path: '/shop-recharge',
    element: shopRecharge
  },
  {
    title: '洗车套餐管理',
    path: '/shop-package',
    element: shopPackage
  },
  {
    title: '店铺管理',
    path: '/shop-list',
    element: shopList
  },
  {
    title: '订单列表',
    path: '/orders',
    element: orders
  },
  {
    title: '充值列表',
    path: '/recharge',
    element: recharge
  },
  {
    title: '数据统计',
    path: '/data-list',
    element: dataList
  },
  {
    title: '余额退款',
    path: '/refund',
    element: refund
  },
  {
    title: '洗车免停管理',
    path: '/free-park',
    element: freePark
  },
  {
    title: '团购映射管理',
    path: '/group-mapping',
    element: groupMapping
  },
  {
    title: '黑名单',
    path: '/blacklist',
    element: blacklist
  }
]
