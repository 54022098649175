import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'

import { cacheKeys } from '../utils/constant'
import { get, set, remove } from '../utils/storage'

export interface IAppState {
  admin?: any
}

export const appSlice = createSlice<IAppState, SliceCaseReducers<IAppState>>({
  name: 'app',
  initialState: {
    admin: _getCache()
  },
  reducers: {
    login: (state, action) => {
      set(cacheKeys.admin, action.payload, Number(action.payload.expires_in) * 1000)
      set(cacheKeys.token, action.payload.access_token, Number(action.payload.expires_in) * 1000)
      state.admin = action.payload
    },
    logout: state => {
      remove(cacheKeys.admin)
      remove(cacheKeys.token)
      state.admin = undefined
    }
  }
})

function _getCache() {
  const c = get(cacheKeys.admin)
  if (c) {
    return c
  }
  remove(cacheKeys.admin)
  return undefined
}
