export const isProduction = !(/localhost|192/.test(window.location.href))
export const http = isProduction ? 'https://' : ''
export const domain = isProduction ? 'admin.lemazzxc.com' : ''
export const host = `${http}${domain}/api`

export const cacheKeys = {
  token: 'TOKEN_CACHE',
  admin: 'ADMIN_CACHE',
  isSuper: 'IS_SUPER_CACHE'
}

// 团购平台类型
export const tuanPlatformType = [
  {
    label: '全部',
    value: 0
  },
  {
    label: '美团',
    value: 1
  },
  {
    label: '高德',
    value: 2
  },
  {
    label: '抖音',
    value: 3
  }
]

// 退款原因
export const refundReason = [
  {
    label: '不在附近',
    value: 1
  },
  {
    label: '设备故障',
    value: 2
  },
  {
    label: '下次再充值',
    value: 3
  },
  {
    label: '洗车体验不太好',
    value: 4
  },
  {
    label: '排队太久',
    value: 5
  },
  {
    label: '其他原因',
    value: 6
  }
]

// 订单退款状态
export const refundStatus = ['未退款', '退款中','已退款']
// 用户状态
export const userBlacklistStatus = ['正常', '拉黑']