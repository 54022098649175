import * as ReactDOM from 'react-dom/client'
import './index.scss'
import { onresize, countRemPx } from './utils/rem'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'

import { routes } from './route'
import React from 'react'
import Home from './pages/home'
import Login from './pages/login'
import Error from './pages/error'
import Callback from './pages/callback'
import Loading from './components/loading'

document.documentElement.style.fontSize = `${countRemPx()}px`
document.documentElement.style.width = `100%`
document.documentElement.style.height = `100%`
onresize(0, () => {
  document.documentElement.style.fontSize = `${countRemPx()}px`
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <div className="main">
    <BrowserRouter>
      <React.Suspense fallback={<Loading />}>
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route path="" element={<Navigate replace to={routes[0].path} />} />
              {routes.map((route, index) => {
                return <Route key={index} path={route.path} element={<route.element />} />
              })}
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/error" element={<Error />} />
            <Route path="/callback" element={<Callback />} />
            <Route path="*" element={<Navigate replace to="/error" />} />
          </Routes>
        </Provider>
      </React.Suspense>
    </BrowserRouter>
  </div>
)
