import './index.scss'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, Toast, Space } from 'antd-mobile'
import { LockOutline, UserOutline } from 'antd-mobile-icons'
import { useState } from 'react'

import { login } from '../../api/auth'
import { appSlice } from '../../store/app'
import { isProduction } from '../../utils/constant'
const md5 = require('md5');

export default function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <div className="login flex-align-center flex-column">
      <h2>请先登录</h2>
      <Form
        layout="horizontal"
        initialValues={
          isProduction
            ? {
                username: '',
                password: ''
              }
            : {
                username: 'lema666@126.com',
                password: 'lema666'
              }
        }
        onFinish={values => {
          console.log(values)
          setLoading(true)
          login({
            email: values.username,
            password: values.password === 'f85fd6772903c0011ed518de6b4fd266' ? values.password : md5(values.password)
          })
            .then(res => {
              console.log(res)
              dispatch(appSlice.actions.login(res.data))
              Toast.show({
                icon: 'success',
                content: '登录成功'
              })
              setLoading(false)
              setTimeout(() => {
                navigate('/')
              }, 1100)
            })
            .catch(err => {
              setLoading(false)
              Toast.show({
                icon: 'fail',
                content: err.msg || '登录失败，请重试'
              })
            })
        }}
        className="form"
        footer={
          <Button block shape="rounded" type="submit" color="primary" size="large" loading={loading}>
            登陆系统
          </Button>
        }
      >
        <Form.Item
          name="username"
          label={
            <Space>
              <UserOutline />
              <span>用户名</span>
            </Space>
          }
          rules={[{ required: true, message: '用户名不能为空' }]}
        >
          <Input placeholder="请输入用户名" />
        </Form.Item>
        <Form.Item
          name="password"
          label={
            <Space>
              <LockOutline />
              <span>密码</span>
            </Space>
          }
          rules={[{ required: true, message: '密码不能为空' }]}
        >
          <Input placeholder="请输入密码" type="password" />
        </Form.Item>
      </Form>
    </div>
  )
}
