declare const window: any

export function sendEvent(name: string, data?: any) {
  console.log('window.collectEvent', name)
  window.collectEvent(name, data)
}

export function searchQuery(key: string): string {
  const split = window.location.href.split('?')
  let res = ''
  const reg = new RegExp(`${key}=`)
  if (split.length > 1 && reg.test(split[1])) {
    split[1].split('&').forEach((item: string) => {
      if (reg.test(item)) {
        if (item.indexOf('#') >= 0) {
          res = item.split('#')[0].split('=')[1]
        } else {
          res = item.split('=')[1]
        }
      }
    })
  }
  return res
}