export const mobileMaxWidth = 680

const _rem: {
  timer: number | NodeJS.Timeout
  onresize: {
    id: number
    fn: () => void
  }[]
} = {
  timer: 0,
  onresize: []
}

function onGlobalResize() {
  if (_rem.timer) clearTimeout(_rem.timer)
  _rem.timer = setTimeout(() => {
    // console.log(window.innerWidth)
    _rem.onresize.forEach(item => {
      item.fn()
    })
  }, 300)
}

window.onresize = onGlobalResize

// 获取设置rem的px
export function countRemPx() {
  if (window.innerWidth <= mobileMaxWidth) {
    return parseInt(`${window.innerWidth / 3.75}`)
  } else {
    return 100
  }
}

export function rem2px(_rem: number) {
  if (window.innerWidth <= mobileMaxWidth) {
    // 1rem / window.innerWidth / 3.75 = _rem / ?
    // window.innerWidth / 3.75 * _rem = ?
    return parseInt(`${window.innerWidth / 3.75}`) * _rem
  }
  return _rem
}

export function px2rem(_px: number) {
  if (window.innerWidth <= mobileMaxWidth) {
    // window.innerWidth / 3.75 * ? = _px
    return `${_px / parseInt(`${window.innerWidth / 3.75}`)}rem`
  }
  return _px
}

export function px2vw(_px: number) {
  return (_px * 100.0) / document.body.clientWidth + 'vw'
}

export function px2percentage(_px: number) {
  return (_px * 100.0) / document.body.clientWidth + '%'
}

export function px2vh(_px: number) {
  return (_px * 100.0) / document.body.clientHeight + 'vh'
}

export function vw2px(_vw: string) {
  return (Number(_vw.replace('vw', '')) * document.body.clientWidth) / 100.0
}

export function onresize(id: number, fn: () => void) {
  _rem.onresize.push({
    id,
    fn
  })
}

export function unresize(id: number) {
  _rem.onresize = _rem.onresize.filter(item => item.id !== id)
}

export function isLarge() {
  return document.body.clientWidth >= 1513
}

export function isSmall() {
  return document.body.clientWidth <= 1334
}

export function isMobile() {
  return document.body.clientWidth <= mobileMaxWidth
}

export function isPad() {
  const ua = navigator && navigator.userAgent ?navigator.userAgent : ''
  const maxTouchPoints = navigator.maxTouchPoints ? navigator.maxTouchPoints : 1
  if (!ua) {
    return false
  }
  return maxTouchPoints > 1 && /iPad|Tablet|Mac/g.test(ua)
}

export function isWeChat() {
  const ua = navigator && navigator.userAgent ?navigator.userAgent : ''
  return isMobile() && /MicroMessenger|WeChat|Weixin/g.test(ua)
}