import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { searchQuery } from '../../utils/window'
import { DotLoading, Result } from 'antd-mobile'
import { fetchMeituanSession } from '../../api'

export default function CallbackPage() {
  const [msg, setMsg] = useState<string>()
  const [content, setContent] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    const code = searchQuery('auth_code')
    const state = searchQuery('state')
    if (code && state === 'xiche') {
      console.log(code)
      fetchMeituanSession(code)
        .then((res: any) => {
          setContent(JSON.stringify(res))
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          setMsg(err.msg || '出现错误，请重试')
          setLoading(false)
        })
    } else {
      setMsg('缺少参数，请重试')
    }
  }, [navigate])

  return (
    <div className="bg-fff flex-center" style={{ height: '100%' }}>
      {loading ? (
        <>
          <DotLoading />
          <span>加载中</span>
        </>
      ) : (
        <Result status={msg ? 'error' : 'success'} title={msg ? '失败' : '成功'} description={msg ? msg : content} />
      )}
    </div>
  )
}
