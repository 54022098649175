import './index.scss'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavBar } from 'antd-mobile'
import { IRoute, routes } from '../../route'
import { HomeOutlined } from '@ant-design/icons'

export default function Home() {
  const location = useLocation()
  const navigate = useNavigate()
  const admin = useSelector<any, any>(state => state.app.admin)
  const [currentRoute, setCurrentRoute] = useState<IRoute>()

  useEffect(() => {
    if (location.pathname) {
      const f = routes.filter(route => route.path === location.pathname)
      if (f.length) {
        setCurrentRoute(f[0])
      }
    }
  }, [location.pathname])

  if (!admin) {
    return <Navigate replace to="/login" />
  }

  return (
    <div className="home flex-column">
      <NavBar
        className="nav flex-shrink"
        back={currentRoute?.path === '/nav' ? null : '返回'}
        right={<HomeOutlined style={{ fontSize: 20 }} onClick={() => navigate('/')} />}
        style={{
          '--border-bottom': '1px #f1f1f1 solid'
        }}
        onBack={() => {
          try {
            navigate(-1)
          } catch (error) {
            console.log(error)
          }
        }}
      >
        <span style={{ color: 'var(--adm-color-primary' }}>{currentRoute?.title}</span>
      </NavBar>
      <div className="">
        <Outlet />
      </div>
    </div>
  )
}
