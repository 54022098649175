import { Result, Button } from 'antd-mobile'
import { SmileOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'

export default function ErrorPage() {
  const navigate = useNavigate()
  return (
    <div className="flex-center bg-fff flex-column" style={{ height: '100%', width: '100%' }}>
      <Result
        icon={<SmileOutline />}
        status="success"
        title="找不到页面"
        description="Sorry, the page you visited does not exist."
      />
      <Button
        color="primary"
        fill="solid"
        onClick={() => {
          navigate('/')
        }}
      >
        返回主页
      </Button>
    </div>
  )
}
